
import { defineComponent, onMounted, ref, reactive } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import DetailEquipement from "@/views/still/contrats/DetailEquipement.vue";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "kt-activite",
  props: {
    widgetClasses: String,
  },
  components: {
    DetailEquipement
  },
  setup() {
    const state = reactive({
      list: [],
      loaderEnabled: true,
      equipementSelect: {},
      drawerDetail: false,
      defaultSearch : "",
    });
    
    onMounted(async () => {
      MenuComponent.reinitialization();
      let rEch = await mAxiosApi.prototype.getAxios("/getEosWarrantyListEquipement");
      state.list = rEch.results;
      state.loaderEnabled = false;
    });
    
    const affDetail = async (ref, ref_parent, constructeur) => {

      let myList = await mAxiosApi.prototype.getAxios("/getEquipementContratbyRef/" +  window.btoa(ref_parent) + "/" + constructeur);
      
      if (ref !== ref_parent) state.defaultSearch = ref;

      state.equipementSelect = myList.results[0];
      state.drawerDetail = true;
    };


    return {
      state,
      affDetail,
    };
  },
});
