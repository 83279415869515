<template>
<div
    style="width: 100%; height: 100%; text-align: center; padding-top: 100px"
    v-if="state.loaderEnabled"
  >
    <div class="lds-ring"><img src="media/logos/Logo-Symbole-couleur.svg"/><div></div><div></div><div></div><div></div></div>
  </div>
  <div class="d-flex flex-column flex-xl-row" v-if="!state.loaderEnabled">
    <div class="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
      <div class="card mb-5 mb-xl-8" style='min-height: 410px'>
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder fs-3 mb-1">{{$t("Parc client sous support")}}</span>

            <span class="text-muted mt-1 fw-bold fs-7" v-if="!state.loaderEnabled">{{$t("Par gamme de produits")}}</span>
          </h3>

          <div v-if="!state.loaderEnabled && state.myList.length == 0" class="alert bg-light-primary alert-primary d-flex align-items-center p-5 mb-10" style="width: 100%; margin-top: 20px;">
            <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
              <inline-svg src="media/icons/duotune/general/gen048.svg" />
            </span>
            <div class="d-flex flex-column">
              <h4 class="mb-1 text-dark">{{$t("Aucun équipement")}}</h4>
              <span>{{$t("Il n'existe pour le moment aucun équipement sous support dans notre base.")}}</span>
            </div>
          </div>


          <div style="width: 100%; height: 100%; text-align: center; padding-top: 20px; padding-bottom: 50px" v-if="state.loaderEnabled" >
            <div class="lds-ring"><img src="media/logos/Logo-Symbole-couleur.svg"/><div></div><div></div><div></div><div></div></div>
          </div>

        </div>
        <div class="card-body pt-5">
          <div class="menu menu-column menu-active-bg menu-hover-bg menu-title-gray-700 fs-6 menu-rounded w-100" id="#kt_aside_menu" data-kt-menu="true">
            <template v-for="(item1, index1) in state.myList" :key="index1">
              <span class="card-label fw-bolder fs-5 mb-1 mt-2 text-primary" v-if="state.myList[index1-1] ? (item1.constructeur == state.myList[index1-1].constructeur ? false : true) : true">{{$t("Equipement")}} {{ item1.constructeur }}</span>
              <div class='menu-item' style="height: 40px;">
                <a @click='affEquipByType( item1.fsh_product_family)' class='menu-link'>
                  <span class='menu-title'>
                    <span class='bullet bg-gray-700 me-5'></span>{{ $t(item1.fsh_product_family) }}</span><span class="menu-badge">
                      <b v-if='item1.prob == 0'>{{ item1.total }}</b>
                      <b v-else class='text-danger'>{{ item1.total }}</b>
                    </span>
                </a>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-lg-row-fluid ms-lg-5">
      <div class="tab-pane fade show active" id="kt_customer_view_overview_tab" role="tabpanel">
        <div class="row g-5 g-xl-12">
          <div class="col-xl-12">
            <overviewContrats widget-classes="card-xl-stretch mb-5 mb-xl-8" ></overviewContrats>
          </div>
          <div class="col-xl-12">
            <EquipementEOS widget-classes="mb-12"></EquipementEOS>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import mAxiosApi from "@/api";
import overviewContrats from "@/views/still/contrats/OverviewContrats.vue";
import EquipementEOS from "@/views/still/contrats/equipementEOS.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "customer-details",
  components: {
    overviewContrats,
    EquipementEOS
  },
  setup() {

    const router = useRouter();

    const affEquipByType = (type) => {
      router.push({  name: "listEquipementbyType", params: { code: type } });
    }

    const state = reactive({
      loaderEnabled: true,
      myList: [],
      totOffre: "",
      totCommande: "",
      totFacture: "",
      rSociete: "",
      rAdresse: "",
      soc_logo: "",
      con_prenom: "",
      con_nom: "",
      con_role: "",
    });

    onMounted(async () => {

      let myList = await mAxiosApi.prototype.getAxios("/getEquipementContrat/byType");
      myList.results.forEach(
        function(item: any){
          //item.contrats_liens = JSON.parse(item.contrats_liens);
        }
      );
      state.myList = myList.results;    
      //console.log('MMMM',state.myList);
      state.loaderEnabled = false;
    });

    return {
      state,
      affEquipByType,
    };
  },
});
</script>
