
import { defineComponent, onMounted, reactive } from "vue";
import mAxiosApi from "@/api";
import overviewContrats from "@/views/still/contrats/OverviewContrats.vue";
import EquipementEOS from "@/views/still/contrats/equipementEOS.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "customer-details",
  components: {
    overviewContrats,
    EquipementEOS
  },
  setup() {

    const router = useRouter();

    const affEquipByType = (type) => {
      router.push({  name: "listEquipementbyType", params: { code: type } });
    }

    const state = reactive({
      loaderEnabled: true,
      myList: [],
      totOffre: "",
      totCommande: "",
      totFacture: "",
      rSociete: "",
      rAdresse: "",
      soc_logo: "",
      con_prenom: "",
      con_nom: "",
      con_role: "",
    });

    onMounted(async () => {

      let myList = await mAxiosApi.prototype.getAxios("/getEquipementContrat/byType");
      myList.results.forEach(
        function(item: any){
          //item.contrats_liens = JSON.parse(item.contrats_liens);
        }
      );
      state.myList = myList.results;    
      //console.log('MMMM',state.myList);
      state.loaderEnabled = false;
    });

    return {
      state,
      affEquipByType,
    };
  },
});
